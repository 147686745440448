import { PSPType } from 'app/models/api/payment';
import { User } from 'app/models/api/user';
import { formattedDate } from 'app/models/date-languages';
import { startOfDay } from 'date-fns';

export const canReactivatePremium = (user: User) => {
    return (
        user.isPremium &&
        user.subscriptionCancelled &&
        !!user.subscription &&
        user.subscriptionPsp === PSPType.adyen &&
        !isPremiumExpired(user)
    );
};

export const canResumePremium = (user: User) => {
    return !user.isPremium && !!user.subscription && user.subscriptionPsp === PSPType.adyen;
};

export const isOnGracePeriod = (user: User) => {
    return user.isPremium && isPremiumExpired(user) && user.subscriptionPsp === PSPType.adyen;
};

export const hasNativeAppPremium = (user: User) => {
    return user.isPremium && (user.subscriptionPsp === PSPType.apple || user.subscriptionPsp === PSPType.google);
};

export const isPremiumExpired = (user: User) => {
    return startOfDay(user.premiumExpiryDate) < startOfDay(new Date());
};

export const formatPremiumExpiryDate = (user: User, localeCode: string) => {
    return formattedDate(user.premiumExpiryDate, 'd MMMM yyyy', localeCode);
};
