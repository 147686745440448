import { Component, computed, EventEmitter, Output } from '@angular/core';
import { BaseOverlayComponent } from 'app/components/common/overlay-content/base-overlay.component';
import { SubscriptionInterface } from 'app/models/api/subscription';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'modules/shared/shared.module';
import { add, intervalToDuration } from 'date-fns';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'premium-winback',
    templateUrl: './premium-winback.component.html',
    styleUrls: ['./premium-winback.component.less'],
    imports: [SharedModule, TranslateModule],
})
export class PremiumWinbackComponent extends BaseOverlayComponent {
    @Output() cancelPremium = new EventEmitter();
    @Output() enableDiscount = new EventEmitter();

    rating = 8.8; // hardcoded for now, since not all countries has ratings
    subscription: SubscriptionInterface;
    get discount() {
        return this.countrySettings?.winbackDiscountPercentage;
    }
    readonly expirationTime = computed(() => {
        const authUser = this.authUserSignal();
        return authUser ? add(new Date(authUser.discountOfferedAt), { hours: 24 }) : undefined;
    });
    readonly currentTime = toSignal(interval(1_000).pipe(map(() => new Date())), { initialValue: new Date() });
    readonly timeToDiscountOfferExpiration = computed(() => {
        const currentTime = this.currentTime();
        const expirationTime = this.expirationTime();
        const duration = expirationTime
            ? intervalToDuration({
                  start: currentTime,
                  end: expirationTime,
              })
            : {};
        const format = (v: number) => v.toString().padStart(2, '0');
        return `${format(duration.hours ?? 0)}:${format(duration.minutes ?? 0)}:${format(duration.seconds ?? 0)}`;
    });

    ngOnInit() {
        if (this.countrySettingsService.countrySettingsOld) {
            this.subscription =
                this.authUser.subscription ??
                this.countrySettingsService.countrySettingsOld.subscriptions.find(item => item.duration === 1) ??
                this.countrySettingsService.countrySettingsOld.subscriptions[0];
        }

        this.data.set({
            title: 'premiumWinback.lifetimeDiscount.title.format',
            titleArgs: { amount: this.discount?.toString() },
            message: this.authUser.isParent
                ? 'premiumWinback.lifetimeDiscount.description.parents'
                : 'premiumWinback.lifetimeDiscount.description.fosters',
            primaryBtn: { title: 'premiumWinback.lifetimeDiscount.continue', action: () => this.enableDiscount.emit() },
            secondaryBtn: { title: 'main.back' },
            linkBtn: {
                title: 'premiumWinback.lifetimeDiscount.cancelAnyway.premium',
                action: () => this.cancelPremium.emit(),
            },
            overlayId: 'managePremium.premiumWinback',
        });
    }
}
