import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { isSupportedCountry } from 'app/models/api/country';
import { RouteService } from 'app/services/route.service';
import { SessionService } from 'app/services/session.service';
import { StorageService } from 'app/services/storage.service';
import { countryCodeRoutes, RouteType } from 'routing/route-type';

const needsCountryCode = (routeType: RouteType) => {
    return countryCodeRoutes.includes(routeType);
};

export const StoredQueryParamsGuard = (_next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const storageService = inject(StorageService);
    const sessionService = inject(SessionService);
    const routeService = inject(RouteService);

    const router = inject(Router);
    const url = state.url;

    const urlTree = router.parseUrl(url);
    let needsToChangeUrl = false;
    storageService.fieldsPopulatedFromQueryParams.forEach(field => {
        let value = urlTree.queryParams[field] as string | undefined;
        if (value) {
            if (
                field === 'countryCode' &&
                isSupportedCountry(value) &&
                needsCountryCode(routeService.getRouteType(state.url)) &&
                storageService.countryCode !== value.toUpperCase()
            ) {
                value = value.toUpperCase();
                sessionService.cleanData(['sitlyAuthProvider']);
            }

            if (field === 'sitlyAuthProvider' || !sessionService.isLoggedIn) {
                storageService[field] = value as never;
            }

            needsToChangeUrl = true;
            delete urlTree.queryParams[field];
        }
    });

    if (needsToChangeUrl) {
        return urlTree;
    }

    return true;
};
