import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from 'app/services/user.service';
import { DefaultRouteService } from 'routing/guards/default-route.service';

export const CompletedUserRouteGuard = (_next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const userService = inject(UserService);
    const defaultRouteService = inject(DefaultRouteService);

    return userService.authUser?.completed ? true : defaultRouteService.createDefaultRoute(state.url);
};
